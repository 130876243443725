import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setToken, verifyTokenAndUpdateUser } from '../features/auth';
import { useVerifyEmailForTicketMutation } from '../features/ticket/ticketApi';

const TrackingEmailVerification = () => {
  const dispatch = useDispatch();

  const queryParams = typeof document === 'object' ? new URLSearchParams(document.location.search) : undefined;

  const email = queryParams?.get('email');
  const otp = queryParams?.get('otp');
  const tracking_code = queryParams?.get('tracking_code');

  const [trigger] = useVerifyEmailForTicketMutation();

  useEffect(() => {
    const verifyEmail = async () => {
      if (email && otp) {
        const response = await trigger({ email, otp });

        if ('data' in response && response.data.access_token) {
          const accessToken = response.data.access_token;
          dispatch(setToken(accessToken));
          dispatch(verifyTokenAndUpdateUser());
          await dispatch(verifyTokenAndUpdateUser());

          navigate(`/ticket/new?trackingCode=${tracking_code}`);
        } else {
          navigate(`/tracking/${tracking_code}`);
        }
      }
    };

    if (typeof document === 'object') {
      verifyEmail();
    }
  }, [email, otp, tracking_code]);

  return (
    <div className="h-full flex items-center justify-center">
      {/* tmp */}
      <h1>Caricamento...</h1>
    </div>
  );
};

export default TrackingEmailVerification;

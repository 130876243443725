import { ShipmentModel } from '../../molecules/trackingId/types';
import { baseApi, CONTENT_TYPE_AUTO } from '../api';

const extended = baseApi.injectEndpoints({
  endpoints: (build) => ({
    registerForTicket: build.mutation<PostRegisterForTicketApiResponse, PostRegisterForTicketApiArg>({
      query: (queryArg) => ({ url: '/spa/tracking/register', method: 'POST', body: queryArg })
    }),
    verifyEmailForTicket: build.mutation<PostVerifyEmailForTicketApiResponse, PostVerifyEmailForTicketApiArg>({
      query: (queryArg) => ({
        url: '/spa/tracking/email-verification',
        method: 'POST',
        body: queryArg
      })
    }),
    getUserTickets: build.query<GetUserTicketsApiResponse, GetUserTicketsApiArg>({
      query: (queryArg) => ({ url: `/spa/tracking/tickets?tracking_code=${queryArg.tracking_code}` })
    }),
    getTicket: build.query<GetUserTicketsByTicketApiResponse, GetUserTicketsByTicketApiArg>({
      query: (queryArg) => ({ url: `/spa/tracking/tickets/${queryArg.ticket}` })
    }),
    addAnswerToTicket: build.mutation<PatchTicketWithAnswerApiResponse, PatchTicketWithAnswerApiArg>({
      query: (queryArg) => ({
        url: `/spa/tracking/tickets/${queryArg.ticket}/answers`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    getTracking: build.query<GetTrackingApiResponse, GetTrackingApiArg>({
      query: (queryArg) => ({ url: `/spa/tracking/shipments/${queryArg.trackingCode}` })
    }),
    postTicket: build.mutation<PostTicketApiResponse, PostTicketApiArg>({
      query: (queryArg) => ({
        url: `/spa/tracking/tickets`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    attachFileToTicket: build.mutation<any, { ticketId: string; body: any }>({
      query: (queryArg) => ({
        url: `/spa/tracking/tickets/${queryArg.ticketId}/attachment`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'Content-Type': CONTENT_TYPE_AUTO
        }
      })
    })
  })
});

export const {
  useRegisterForTicketMutation,
  useGetUserTicketsQuery,
  useGetTicketQuery,
  useLazyGetTicketQuery,
  useAddAnswerToTicketMutation,
  useGetTrackingQuery,
  usePostTicketMutation,
  useVerifyEmailForTicketMutation,
  useAttachFileToTicketMutation
} = extended;

export type PostRegisterForTicketApiResponse = {
  data?: unknown;
};
export type PostRegisterForTicketApiArg = {
  tracking_code: string;
  email: string;
  name: string;
  password: string;
};

export type PostVerifyEmailForTicketApiResponse = {
  access_token?: string;
};
export type PostVerifyEmailForTicketApiArg = {
  email: string;
  otp: string;
};

export type GetUserTicketsApiResponse = {
  data?: TicketList;
};
export type GetUserTicketsApiArg = {
  tracking_code?: string;
};

export type GetUserTicketsByTicketApiResponse = {
  data?: TicketDetail;
};
export type GetUserTicketsByTicketApiArg = {
  /** ticket id */
  ticket: string;
};

export type TicketList = {
  id?: number;
  status: {
    name: string;
    cssclass: string;
  };
  priority: number;
  created_at: string;
  updated_at: string;
}[];

export type TicketDetail = {
  id: number;
  assignee?: {
    name: string;
    email: string;
    avatar_url: string;
  };
  shipment?: ShipmentModel;
  status: {
    name: string;
    cssclass: string;
    is_closed: string;
  };
  priority: number;
  contents: string;
  answers: {
    contents: string;
    updated_at: string;
    author?: {
      name: string;
      email: string;
      avatar_url: string;
    };
    is_yours: boolean;
    attachment_url?: string;
    attachment_name?: string;
  }[];
  is_yours: boolean;
  closed_at?: string;
  created_at: string;
  updated_at: string;
};

export type Answer = TicketDetail['answers'][0];

export type PatchTicketWithAnswerApiResponse = {
  data?: ShipmentModel;
};
export type PatchTicketWithAnswerApiArg = {
  /** ticket id */
  ticket: string;
  /** Add an answer to the ticket */
  body: {
    answer: string;
    mark_resolved?: boolean;
  };
};

export type GetTrackingApiResponse = {
  data?: ShipmentModel;
};
export type GetTrackingApiArg = {
  /** shipment tracking code */
  trackingCode: string;
};

export type PostTicketApiResponse = {
  data?: TicketDetail;
};
export type PostTicketApiArg = {
  /** New Ticket  */
  body: {
    contents: string;
    shipment?: string;
  };
};
